import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { 
    SiteFooter,
    FooterLogo,
    SocialFooterLink,
    UpperFooter,
    FooterLinks
} from "./styles"
import { 
    MaxContainer,
    ThreeColumnGridAlignTop,
    Column
} from "../../styles/utility"
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import ContactForm from "../Contact"

const Footer = () => (
 <SiteFooter>
     <UpperFooter>
        <MaxContainer>
            <FooterLogo>
                <StaticImage 
                    src="../../images/ndc-logo-footer.png" 
                    alt="Norwest Dental Care"
                    width={475} 
                />
            </FooterLogo>
            <ul>
            <li><SocialFooterLink href="https://www.facebook.com/norwestdentalcare1" target="_blank" rel="noopener noreferrer"><FaFacebookF/>Facebook</SocialFooterLink></li>
            <li><SocialFooterLink href="https://www.instagram.com/norwestdentalcare/" target="_blank" rel="noopener noreferrer"><FaInstagram/>Instagram</SocialFooterLink></li>
            </ul>
        </MaxContainer>
     </UpperFooter>
     <MaxContainer>
        <ThreeColumnGridAlignTop>
            <Column>
            <h3>Links</h3>
            <FooterLinks>
                <li><a href="/meet-the-team/">Meet the Team</a></li>
                <li><a href="/our-clinic/">Our Clinic</a></li>
                <li><a href="/special-offers/">Special Offers</a></li>
                <li><a href="/patient-resources/">Patient Resources</a></li>
                <li><a href="/testimonials/">Testimonials</a></li>
            </FooterLinks>
            <h3>Services</h3>
            <FooterLinks>
                <li><a href="/services/general-dentistry/">General Dentistry</a></li>
                <li><a href="/services/general-dentistry/teeth-cleaning/">Teeth Cleaning</a></li>
                <li><a href="/services/cosmetic-dentistry/">Cosmetic</a></li>
                <li><a href="/services/cosmetic-dentistry/dental-implants/">Dental Implants</a></li>
                <li><a href="/services/gum-therapy/">Gum Therapy</a></li>
            </FooterLinks>
            </Column>
            <Column>
                <h3>Info</h3>
                <p>Operating Hours: <br/>
                Mon - Fri: 9am - 4:30pm <br/>
                Wed: 2pm - 7pm <br/>
                Saturday: By appointment only <br/>
                Sunday: Closed 
                </p>
                <p>Address: <a href="https://goo.gl/maps/Gg7kxoueMXNduTs77" target="_blank" rel="noopener noreferrer">Suite 118 - Level 1, Building B 20 Lexington Drive, Bella Vista NSW 2153</a></p>
                <p>Email: <a href="mailto:info@norwestdentalcare.com.au">info@norwestdentalcare.com.au</a></p>
                <p> Phone: <a href="tel:1300886758">1300 886 758</a></p>
            </Column>
            <Column>
                <ContactForm />
            </Column>
        </ThreeColumnGridAlignTop>
     </MaxContainer>
 </SiteFooter>
)


export default Footer
