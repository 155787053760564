import styled from 'styled-components'
import theme from "../../styles/utility"

export const SiteHeader = styled.header`
    font-family: 'Nunito Sans', sans-serif;
    position: fixed;
    top: 0;
    z-index: 1;
    left: 0;
    width: 100%;
    a {
        @media all and (min-width: 1024px) {
            font-size: 14px;
        }
        @media all and (min-width: 1170px) {
            font-size: 15px;
        }
    }
`;

export const UpperHeader = styled.div`
    background-color: ${theme.colors.secondaryBlue};
    @media all and (min-width: 1024px) {
        display: block;
    }
    p {
        font-size: 0.8125rem;
        color: ${theme.colors.primaryBlue};
        margin: 0;
    }
    ul {
        display: flex;
        margin: 0;
    }
    li {
        list-style: none;
        padding: 0.5rem;
    }
    a {
        color: ${theme.colors.primaryBlue};
        font-size: 13px;
    }
`;

export const MainHeader = styled.div`
    padding: 10px 0;
    background-color: #fff;
`;

export const MobileMenuButton = styled.button`
    height: 40px;
    border: 0;
    background: none;
    cursor: pointer;
    -webkit-transition-duration: 0s; transition-duration: 0s;
	-webkit-transition-delay: 0.2s; transition-delay: 0.2s;
    span,
    span:before, 
    span:after {
        width: 28px;
        height: 3px;
        background-color: #222;
        display: block;
        transition: ${(props) =>
            props.open ? 'all 0.25s ease-in' : 'all 0.25s ease-out'};
    }
    span {
        background-color: ${(props) => (props.open ? 'rgba(0,0,0,0.0)' : '#222')};
    }
    span:before, 
    span:after {
        position: absolute;
        content: '';
    }
    span:before {
        margin-top: ${(props) => (props.open ? '0' : '-10px')};
        transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    span:after {
        margin-top: ${(props) => (props.open ? '0' : '10px')};
        transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
        
    }
`;

export const HeaderLogo = styled.a`
    @media all and (max-width: 1023px) {
        max-width: 170px;
    }
`;

export const SocialLink = styled.a`
    display:flex;
    align-items: center;
    svg {
        margin-right: 5px;
    }
`;

export const DesktopServices = styled.a`
    cursor: pointer;
    @media all and (max-width: 1023px) {
        display: none;
    }
`;

export const HeaderNavigation = styled.nav`
    position: relative;
    @media all and (max-width: 1023px) {
        position: fixed;
        overflow: hidden;
        left: ${(props) => (props.open ? '0' : '-100%')};
        transition: ${(props) =>
            props.open ? 'left 0.25s ease-out' : 'left 0.6s ease-out'};
        top: 0;
        z-index: 1;
        flex-direction: column;
        width: 85%;
        background: #fff;
        padding-bottom: 50vh;
        padding-top: 10vh;
        li {
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            &:hover {
                background-color: ${theme.colors.primaryBlue};
                a {
                    color: #fff;
                    border-bottom: 0;
                }
            }
        }
        a {
            width: 100%;
            display: block;
            padding: 0.5rem 0;
        }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    li {
        list-style: none;
        padding: 0.5rem;
        position: relative;
    }
    a {
        text-decoration: none;
        color: #222;
        &:hover {
            border-bottom: 2px solid;
        }
    }
`;

export const HeaderRightSide = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
        list-style: none;
        margin-left: 16px;
    }
`;

export const AnnouncementBar = styled.div`
    background-color: ${theme.colors.primaryBlue};
    padding: 10px 0;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: -2;
`;

export const SubNavigation = styled.ul`
    background-color: ${theme.colors.primaryBlue};
    margin: 0;
    padding-left: 0;
    a {
        color: #fff;
    }
    li {
        padding: 0;
    }
    @media all and (min-width: 1024px) {
        display: ${(props) => (props.open ? 'block' : 'none')};
        position: absolute;
        top: 35px;
        min-width: 180px;
        left: 0;
        padding: 10px 15px;
        li {
            position: relative;
        }
        a {
            border-bottom: 0;
            padding: 5px;
            display: block;
            &:hover {
                border-bottom: 0;
                text-decoration: underline;
            }
        }
    }
`;

export const SubCategoryNavigation = styled.ul`
    margin: 0;
    padding: 0;
`;