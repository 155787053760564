import * as React from "react"
import PropTypes from "prop-types"
import { 
    SectionTitle,
    ContentSection
} from "./styles"
import { 
    MaxContainer,
    Row
  } from "../../styles/utility"

const Section = ({ pageTitle, sectionTitle, className, children }) => (
 <ContentSection className={className}>
     <MaxContainer>
        <Row>
            {pageTitle &&
                <SectionTitle>
                <h1>{pageTitle}</h1>
                </SectionTitle>
            }
            {sectionTitle &&
                <SectionTitle>
                <h2>{sectionTitle}</h2>
                </SectionTitle>
            }
            <div>{children}</div>
        </Row>
     </MaxContainer>
 </ContentSection>
)

Section.defaultProps = {
    sectionTitle: ''
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
  }


export default Section
