import styled from 'styled-components'
import theme from "../../styles/utility"

export const SectionTitle = styled.div`
    padding: 10px 0;
    text-align: center;
    h1 {
        color: ${theme.colors.primaryBlue};
        text-transform: capitalize;
        font-size: 1.8rem;
        margin-bottom: 0;
    }
    h2 {
        color: ${theme.colors.primaryBlue};
        text-transform: capitalize;
        margin-bottom: 0;
    }
`;

export const ContentSection = styled.div`
    margin: 1rem auto;
    padding: 1rem 0;
    .gridColumn {
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        grid-template-rows: auto;
        gap: 2rem 0;
        align-items: baseline;
        @media all and (min-width: 768px) {
          gap: 0px 2rem;
        }
        img {
          max-width: 100%;
        }
        .gridColumn-one {

        }
        &.gridColumn-two {
            margin-bottom: 4rem;
            @media all and (min-width: 768px) {
              grid-template-columns: 1fr 1fr;
            }
        }
        &.gridColumn-three {
            @media all and (min-width: 768px) {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 1rem;
              }
              @media all and (min-width: 1024px) {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 1rem;
                grid-template-areas:
                  ". . .";
              }
        }
    }
    ul {
        font-size: 1.125rem;
        line-height: 1.5;
        text-align: left;
    }
`;