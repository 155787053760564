import styled from 'styled-components'

const theme = {
    colors: {
        primaryBlue: "#35aac4",
        secondaryBlue: "#d7eef3"
    },
    maxWidth: '1170px',
    gutter: '16px',
    maxTextWidth: '720px',
    breakpoints: {
      xs: '400px',
      s: '600px',
      m: '900px',
      l: '1200px',
    },
}

export default theme;

export const Column = styled.div`
img {
  display: block;
  margin: 0 auto;
}
`;

export const MaxImageSize = styled.div`
  max-width: 200px;
  display: block;
  margin: 0 auto;
  transition: 0.3s ease transform;
&:hover {
  transform: scale(1.1);
  cursor: pointer;
}
`;

export const Quotation = styled.blockquote`
  text-align: center;
  font-size: 1.2rem;
  padding: 1.5rem 0;
  line-height: 1.5;
  color: #fff;
  max-width: ${theme.maxTextWidth};
  margin: 0 auto;
  width: 100%;
  @media all and (min-width: 768px) {
    font-size: 1.3rem;
  }
`;


export const PageHeader = styled.div`
  position: relative;
`;

export const TextContent = styled.div`
  max-width: ${theme.maxTextWidth};
  margin: 1rem auto 2rem;
  width: 100%;
  text-align: center;
`;

export const BlogPost = styled.div`
  margin-bottom: 2rem;
`;

export const TestimonialPost = styled.div`
padding: 10px 30px;
margin: 10px;
border: 1px solid ${theme.colors.primaryBlue};
p {
  font-size: 1rem;
}
`;

export const ContentTitle = styled.h3`
  color: ${theme.colors.primaryBlue};
  font-size: 1.5rem;
  font-weight: 600;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  grid-template-rows: auto;
  gap: 2rem 0;
  @media all and (min-width: 768px) {
    gap: 0px 2rem;
  }
  img {
    max-width: 100%;
  }
`;

export const TwoColumnGrid = styled(Grid)`
  margin-bottom: 4rem;
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ThreeColumnGrid = styled(Grid)`
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
  }
  @media all and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 1rem;
    grid-template-areas:
      ". . .";
  }
`;

export const ThreeColumnGridAlignTop = styled(ThreeColumnGrid)`
  align-items: flex-start;
`;

export const FourColumnGrid = styled(Grid)`
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;

  }
  @media all and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 1rem;
    grid-template-areas:
      ". . . .";
  }
`;

export const BlogGrid = styled(FourColumnGrid)`
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  grid-template-rows: 1fr;
  gap: 1rem;
  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media all and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const CenterAlign = styled.div`
  text-align: center;
  margin: 1rem 0;
`;

export const Row = styled.div`
  width: 100%;
`;

export const Service = styled.div`
  text-align: center;
  h3 {
    text-align: center;
    color: ${theme.colors.primaryBlue};
    background-color: ${theme.colors.secondaryBlue};
    display: inline-block;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    font-weight: 400;
    border-radius: 15px;
  }
`;

export const Deal = styled.div`
  text-align: center;
  margin: 1.5rem;
  a {
    margin-top: 2rem;
  }
`;

export const DealMain = styled.div`
  background-color: ${theme.colors.secondaryBlue};
  padding: 1rem;
  @media all and (min-width: 1024px) {
    min-height: 320px;
  }
  h3 {
    color: ${theme.colors.primaryBlue};
    font-size: 1.5rem;
  }
  p {
    color: ${theme.colors.primaryBlue};
    font-size: 1rem;
  }
`;

export const FlexBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const MaxContainer = styled(FlexBetween)`
    max-width: 100%;
    padding-left: ${theme.gutter};
    padding-right: ${theme.gutter};
    margin: 0 auto;
    width: 100%;
    @media all and (min-width: 768px) {
      max-width: ${theme.maxWidth};
    }
`;

export const Button = styled.a`
    padding: 0.5rem 1.5rem;
    display: inline-block;
    transition: 0.3s ease-out background-color, 0.3s ease-out color;
    border: 1px solid;
    &:hover {
      text-decoration: none;
    }
`;

export const PrimaryButton = styled(Button)`
    background-color: ${theme.colors.primaryBlue};
    border-color: ${theme.colors.primaryBlue};
    border-radius: 25px;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: ${theme.colors.primaryBlue};
      border-color: ${theme.colors.primaryBlue};
    }
`;

export const ExternalButton = styled.button`
    padding: 0.5rem 1.5rem;
    display: inline-block;
    transition: 0.3s ease-out background-color, 0.3s ease-out color;
    border: 1px solid;
    background-color: #fff;
    background-color: ${theme.colors.secondaryBlue};
    border-color: ${theme.colors.secondaryBlue};
    border-radius: 25px;
    color: ${theme.colors.primaryBlue};
    cursor: pointer;
    &:hover {
      background-color: #fff;
      border-color: ${theme.colors.primaryBlue};
      text-decoration: none;
    }
`;

export const SecondaryButton = styled(Button)`
    background-color: ${theme.colors.secondaryBlue};
    border-color: ${theme.colors.secondaryBlue};
    border-radius: 25px;
    color: ${theme.colors.primaryBlue};
    &:hover {
      background-color: ${theme.colors.primaryBlue};
      color: #fff;
    }
`;