import React, { useState } from "react"
import PropTypes from "prop-types"
import { RichText } from 'prismic-reactjs'
import { StaticImage } from "gatsby-plugin-image"
import { 
  SiteHeader, 
  UpperHeader, 
  HeaderLogo,
  MainHeader,
  MobileMenuButton, 
  HeaderNavigation,
  HeaderRightSide,
  SubNavigation,
  SubCategoryNavigation,
  DesktopServices,
  SocialLink,
  AnnouncementBar
} from "./styles"
import { 
  MaxContainer,
  PrimaryButton,
} from "../../styles/utility"
import { FaFacebookF, FaInstagram, FaMapMarkerAlt } from "react-icons/fa";

const Header = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <SiteHeader>
    <UpperHeader>
      <MaxContainer>
        <p><SocialLink href="https://goo.gl/maps/Gg7kxoueMXNduTs77" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt/><span className="hide-mobile">Suite 118 - Level 1, Building B 20 Lexington Drive, Bella Vista NSW 2153</span></SocialLink></p>
        <p className="hide-mobile">Open 6 days a week. <a href="#trading-hours">See trading hours here</a></p>
        <ul>
          <li><SocialLink href="https://www.facebook.com/norwestdentalcare1" target="_blank" rel="noopener noreferrer"><FaFacebookF/><span className="hide-mobile">Facebook</span></SocialLink></li>
          <li><SocialLink href="https://www.instagram.com/norwestdentalcare/" target="_blank" rel="noopener noreferrer"><FaInstagram/><span className="hide-mobile">Instagram</span></SocialLink></li>
        </ul>
      </MaxContainer>
    </UpperHeader>
    <MainHeader>
      <MaxContainer>
        <HeaderLogo href="/">
          <StaticImage 
            src="../../images/ndc-logo.jpg" 
            alt="Norwest Dental Care"
            width={175} 
          />
        </HeaderLogo>
        <HeaderNavigation open={open}>
            <li><a href="/meet-the-team/">Meet the Team</a></li>
            <li><a href="/our-clinic/">Our Clinic</a></li>
            <li>
              <DesktopServices className="hide-mobile" onClick={() => setOpen(!open)}>
                Services
              </DesktopServices>
              <a className="hide-desktop" href="/services/">Services</a>
              <SubNavigation open={open}>
                <li>
                  <a href="/services/general-dentistry/">General Dentistry</a>
                  <SubCategoryNavigation>
                    <li><a href="/services/general-dentistry/teeth-cleaning/">Teeth Cleaning</a></li>
                  </SubCategoryNavigation>
                </li>

                <li><a href="/services/gum-therapy/">Gum Therapy</a></li>
                <li>
                  <a href="/services/cosmetic-dentistry/">Cosmetic Dentistry</a>
                  <SubCategoryNavigation>
                    <li><a href="/services/cosmetic-dentistry/dental-implants/">Dental Implants</a></li>
                  </SubCategoryNavigation>
                  <SubCategoryNavigation>
                    <li><a href="/services/cosmetic-dentistry/invisalign/">Invisalign</a></li>
                  </SubCategoryNavigation>
                </li>
              </SubNavigation>
            </li>
            <li><a href="/testimonials/">Testimonials</a></li>
            <li><a href="/patient-resources/">Patient Resources</a></li>
            <li><a className="hide-desktop"  href="https://apac.dentalhub.online/soe/new/Norwest%20Dental%20Care?pid=OARDA01"  target="_blank" rel="noopener noreferrer">Book Appointment</a></li>
        </HeaderNavigation>
        <HeaderRightSide>
            <li className="phone-mobile"><a href="tel:1300886758">1300 886 758</a></li>
            <li className="hide-mobile"><PrimaryButton href="https://apac.dentalhub.online/soe/new/Norwest%20Dental%20Care?pid=OARDA01"  target="_blank" rel="noopener noreferrer">Book Appointment</PrimaryButton></li>
            <li className="hide-desktop">
              <MobileMenuButton 
                role="button"
                onClick={() => setOpen(!open)}
                open={open}
              >
                <span/>
              </MobileMenuButton>
            </li>
        </HeaderRightSide>
      </MaxContainer>
    </MainHeader>
    <AnnouncementBar>
      {RichText.asText(props.data.announcement)}
    </AnnouncementBar>
  </SiteHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
