
import * as React from "react"
import { 
    ExternalButton,
  } from "../../styles/utility"

const ContactForm = () => (
    <form name="contact" netlify-honeypot="bot-field" method="POST" action="/thank-you" data-netlify="true" netlify="true">
        <h3>Contact Us</h3>
        <p>
            <label htmlFor="name">Name: </label><input type="text" name="name" required />   
        </p>
        <p>
            <label htmlFor="email">Email: </label><input type="email" name="email" required />
        </p>
        <p>
            <label htmlFor="phone">Phone Number: </label><input type="phone" name="phone" required />
        </p>
        <p>
            <label htmlFor="message">Message: </label><textarea name="message"></textarea>
        </p>
        <p className="hidden">
            <label>Don't fill this out if you're human: <input name="bot-field" /></label>
        </p>
        <p>
            <ExternalButton type="submit">Submit</ExternalButton>
        </p>
    </form>
)

export default ContactForm;