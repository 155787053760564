import styled from 'styled-components'
import theme from "../../styles/utility"

export const SiteFooter = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    background-color: ${theme.colors.primaryBlue};
    color: #fff;
    padding-top: 2rem;
    padding-bottom: 2rem;
    a {
        text-decoration: none;
        color: #fff;
    }
    p {
        font-size: 15px;
    }
    @media all and (max-width: 768px) {
        text-align: center;
    }
`;

export const SocialFooterLink = styled.a`
    display:flex;
    align-items: center;
    svg {
        margin-right: 5px;
    }
`;

export const FooterLogo = styled.div`
    
`;

export const UpperFooter = styled.div`
    margin-bottom: 2.5rem;
    ul {
        display: flex;
        margin: 0;
        li {
            list-style: none;
            margin-left: 1rem;
        }
    }
`;

export const FooterLinks = styled.ul`
    padding-left: 0;
    li {
        list-style: none;
    }
    a {
        line-height: 1.5;
    }
`;